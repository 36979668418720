<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="enttarcount">
        核算
    </div>
</template>

<script>
// import { } from '../../api/api.js'
export default {
  name: 'enttarcount',
  data () {
    return {
            
    }
  },
  mounted () {
  },
  created () {
        
  },
  watch: {
        
  },
  methods: {
        
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './quanxian.scss';
</style>
